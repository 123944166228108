import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Quote from "../components/Quote";
import Layout, { PageLayout } from "../components/Layout";
import { graphql } from "gatsby";
import {
  MaybeStickyTitle,
  Title,
  TitleDescription,
  SectionTitle,
} from "../components/Titles";
import Nest from "../components/Nest";
import { ArticleItem, ArticleList } from "../components/Articles";

const Archives = styled.button`
  font-size: 14px;
  cursor: pointer;
  display: grid;
  align-content: center;
  outline: none;
  font-style: italic;
  text-align: left;
  background: none;
  color: var(--body-text-color);
  border: none;
  cursor: pointer;
`;

const ArchivesTitleLayout = styled.div`
  padding: 24px 0;
  grid-column: 1 / -1;
`;

const ArchivesExplanation = styled.div`
  color: var(--body-text-color);
  line-height: 1.5;
`;

const HideArchives = styled.button`
  color: var(--body-text-color);
  background: none;
  border: none;
  padding: 8px 0 0 0;
  outline: none;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  font-style: italic;
`;

class IndexPage extends React.Component {
  state = {
    unashamed: false,
  };

  handleShameClick = () => {
    this.setState(
      ({ unashamed }) => ({
        unashamed: !unashamed,
      }),
      () => {
        if (this.state.unashamed && this.shame) {
          this.shame.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    );
  };

  render() {
    const { data } = this.props;
    const content = this.state.unashamed
      ? "Oh, the shame! Considered changing my name!"
      : "Indeed the business of the universe is to make such a fool out of you that you will know yourself for one, and begin to be wise.";
    const source = this.state.unashamed ? "Pumba" : "George Macdonald";
    return (
      <PageLayout slug={this.props.location.pathname}>
        <Nest>
          <Header />
          <Quote content={content} source={source} />
          <Layout
            left={
              <MaybeStickyTitle>
                <Title>Engineering, Systems & General Postulations</Title>
                <TitleDescription>
                  In pursuit of the timeless and great as it pertains to
                  accomplishing amazing things with other people.
                </TitleDescription>
              </MaybeStickyTitle>
            }
            right={
              <ArticleList>
                {data.allMdx.edges
                  .filter(({ node }) => !node.frontmatter.archived)
                  .map(({ node }) => (
                    <ArticleItem
                      key={node.id}
                      timeToRead={node.timeToRead}
                      title={node.frontmatter.title}
                      description={node.frontmatter.excerpt}
                      slug={node.fields.slug}
                    />
                  ))}

                {!this.state.unashamed ? (
                  <Archives onClick={this.handleShameClick}>
                    Archives of Shame &raquo;
                  </Archives>
                ) : null}
                {this.state.unashamed ? (
                  <ArchivesTitleLayout
                    ref={(el) => {
                      this.shame = el;
                    }}
                  >
                    <SectionTitle>Archives of Shame</SectionTitle>
                    <ArchivesExplanation>
                      Here you&apos;ll find my shameful technical history. Chalk
                      full of ideologies I no longer subscribe to, tools I
                      don&apos;t use and ideas that were horribly misled. If
                      it&apos;s too much for you to carry, I understand.
                    </ArchivesExplanation>
                    <HideArchives onClick={this.handleShameClick}>
                      Hide them again. &raquo;
                    </HideArchives>
                  </ArchivesTitleLayout>
                ) : null}

                {this.state.unashamed
                  ? data.allMdx.edges
                      .filter(({ node }) => node.frontmatter.archived)
                      .map(({ node }) => (
                        <ArticleItem
                          key={node.id}
                          timeToRead={node.timeToRead}
                          title={node.frontmatter.title}
                          description={node.frontmatter.excerpt}
                          slug={node.fields.slug}
                        />
                      ))
                  : null}
              </ArticleList>
            }
          />
        </Nest>
      </PageLayout>
    );
  }
}

export const query = graphql`
  query Articles {
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/articles/*.mdx" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            archived
            excerpt
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
